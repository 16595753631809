* {
    margin: 0;
    font-family: 'Poppins', sans-serif;

}

.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-top: 100px;
}

.widgetStory {
    /*max-width: 650px;*/
    /*min-width: 280px;*/
    border: 3px solid black;
    width: 35%;
    height: 400px;
    border-radius: 15px;
    margin: 20px;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;

    overflow-y: scroll;



}

::-webkit-scrollbar {
    width: 6px;
}



::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* -webkit-box-shadow: inset 3px 3px 6px rgb(0 0 0); */

    background-color: #ffffffa3;
}

.widgetAthlete {
    /*max-width: 650px;*/
    /*min-width: 280px;*/
    width: 35%;
    height: 705px;
    border-radius: 15px;
    margin: 20px;
    position: relative;
    top: -255px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 3px solid black;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;



}

.corp {
    display: flex;
    flex-wrap: wrap;
}
.widgetPodium {
    /*max-width: 650px;*/
    /*min-width: 280px;*/
    width: 35%;
    height: 672px;
    border: 3px solid black;
    border-radius: 15px;
    margin: 20px;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;



}
.widgetQuizz {
    /*width: 650px;*/
    /*min-width: 280px;*/
    width: 35%;
    border-radius: 15px;
    /* background-color: #da4545; */
    margin: 20px;
    height: 454px;
    border: 3px solid black;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;


}

.widgetAthlete h3, .widgetPodium h3, .widgetQuizz h3, .widgetStory h3 {

    font-size: 30px;
    margin-top: 15px;

}

.anecdotetitle {
    color: #5656e1;
}

.imagmedaille {
    width: 30px;
}

.third p {
    margin: 15px;
}
.first {
    display: flex;
    margin: auto;
    align-items: center;


}

.second {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.third {
    display: flex;
    margin: auto;

}

.nationality {
    display: flex;
    justify-content: center;
}

.medaille {
    font-size: 25px;
}

.imgpays {


}

.card {
    width: 40%;
    margin: 20px auto;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px;
}



.cardsearch {
    width: 60%;
    margin: 20px auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 9px;
    box-shadow: rgba(100,100,101,0.4) 0px 7px 29px 0;

}



.imgpays {
    width: 40px;
}




